// api 
// import { useGetInvoices } from 'src/api/stripe';
// @mui
import Grid from '@mui/material/Unstable_Grid2';
// mock
import { _paymentPlans } from 'src/_mock/_paymentPlans';
//
import AccountBillingPlan from './account-billing-plan';
import AccountBillingHistory from './account-billing-history';




// ---------------------------------------------------------------------

export default function AccountBilling() {

  // const { invoices } = useGetInvoices('cus_OmxsAZAKkyT6MV');


  return (
    <Grid container spacing={5} disableEqualOverflow>
      <Grid xs={12} md={8}>
        <AccountBillingPlan plans={_paymentPlans} />
      </Grid>

      <Grid xs={12} md={4}>
        {/* <AccountBillingHistory invoices={invoices} /> */}
      </Grid>
    </Grid>
  );
}

import { ILangWidget } from 'src/types/product';

export const LangTextblock: ILangWidget = [
  {
    feId: 'TextBlockContent',
    desc: [
      { lang: 'en', val: 'Paragraph' },
      { lang: 'de', val: 'Paragraph' }, 
    ],
    value: [
      { lang: 'en', val: ['Lorem Ipsum'] },
      { lang: 'de', val: ['Lorem Ipsum'] }, 
    ],
  },
  
];
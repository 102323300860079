//
import { v4 as uuid } from 'uuid';
import { IWidget } from 'src/types/product';
import { getDesc, getValue } from '../_langWidgets';

// ----------------------------------------------------------------------

export const ProductInfo: IWidget = {
  id: uuid(),
  name: 'ProductInfo',
  order: 0,
  category: 'Required',
  open: false,
  label: {
    id: 'productinfo',
    name: 'Product Info',
    color: 'black',
  },
  childs: [
    {
      id: 'Head',
      open: true,
      show: true,
      attributes: {
        '1': {
          feId: 'WidgetHeadline_ProductInformation',
          change: false,
          translate: true,
          keyvalue: false,
          name: getDesc('WidgetHeadline_ProductInformation'),
          value: getValue('WidgetHeadline_ProductInformation'),
        },
        '2': {
          feId: 'ShowWidgetHeadline',
          change: false,
          translate: false,
          keyvalue: false,
          name: getDesc('ShowWidgetHeadline'),
          value: getValue('ShowWidgetHeadline'),
        },
        '3': {
          feId: 'Description',
          change: true,
          translate: true,
          keyvalue: false,
          name: getDesc('Description'),
          value: getValue('Description'),
        },
        '4': {
          feId: 'BasicInformation',
          change: true,
          translate: true,
          keyvalue: true,
          name: getDesc('BasicInformation'),
          value: getValue('BasicInformation'),
        },
      },
    },
    {
      id: 'Nutrients',
      open: false,
      show: true,
      attributes: {
        '1': {
          feId: 'WidgetHeadline_Nutrients',
          change: false,
          translate: true,
          keyvalue: false,
          name: getDesc('WidgetHeadline_Nutrients'),
          value: getValue('WidgetHeadline_Nutrients'),
        },
        '2': {
          feId: 'Nutrients',
          change: true,
          translate: false,
          keyvalue: true,
          name: getDesc('Nutrients'),
          value: getValue('Nutrients'),
        },
        '3': {
          feId: 'CustomFields',
          change: true,
          translate: true,
          keyvalue: true,
          name: getDesc('CustomFields'),
          value: getValue('CustomFields'),
        },
        '4': {
          feId: 'ServingUnit',
          change: true,
          translate: false,
          keyvalue: false,
          name: getDesc('ServingUnit'),
          value: getValue('ServingUnit'),
        },
        '5': {
          feId: 'ReferenceQuantity',
          change: true,
          translate: false,
          keyvalue: false,
          name: getDesc('ReferenceQuantity'),
          value: getValue('ReferenceQuantity'),
        },
        '6': {
          feId: 'ServingSize',
          change: true,
          translate: false,
          keyvalue: false,
          name: getDesc('ServingSize'),
          value: getValue('ServingSize'),
        },
        '7': {
          feId: 'EnableServingSize',
          change: true,
          translate: false,
          keyvalue: false,
          name: getDesc('EnableServingSize'),
          value: getValue('EnableServingSize'),
        },
      },
    },
    {
      id: 'Ingredients',
      open: false,
      show: true,
      attributes: {
        '1': {
          feId: 'WidgetHeadline_Ingredients',
          change: false,
          translate: true,
          keyvalue: false,
          name: getDesc('WidgetHeadline_Ingredients'),
          value: getValue('WidgetHeadline_Ingredients'),
        },
        '2': {
          feId: 'Ingredients',
          change: true,
          translate: true,
          keyvalue: false,
          name: getDesc('Ingredients'),
          value: getValue('Ingredients'),
        },
        '3': {
          feId: 'SelectAllergens',
          change: true,
          translate: true,
          keyvalue: false,
          name: getDesc('SelectAllergens'),
          value: getValue('SelectAllergens'),
        },
        '4': {
          feId: 'NotesAndWarnings',
          change: true,
          translate: true,
          keyvalue: false,
          name: getDesc('NotesAndWarnings'),
          value: getValue('NotesAndWarnings'),
        },
      },
    },
    {
      id: 'Distributor',
      open: false,
      show: true,
      attributes: {
        '1': {
          feId: 'WidgetHeadline_Distributor',
          change: false,
          translate: true,
          keyvalue: false,
          name: getDesc('WidgetHeadline_Distributor'),
          value: getValue('WidgetHeadline_Distributor'),
        },
        '2': {
          feId: 'ResponsibleDistributor',
          change: true,
          translate: true,
          keyvalue: false,
          name: getDesc('ResponsibleDistributor'),
          value: getValue('ResponsibleDistributor'),
        },
        '3': {
          feId: 'PostalCode',
          change: false,
          translate: false,
          keyvalue: false,
          name: getDesc('PostalCode'),
          value: getValue('PostalCode'),
        },
        '4': {
          feId: 'City',
          change: true,
          translate: false,
          keyvalue: false,
          name: getDesc('City'),
          value: getValue('City'),
        },
        '5': {
          feId: 'Street',
          change: true,
          translate: false,
          keyvalue: false,
          name: getDesc('Street'),
          value: getValue('Street'),
        },
        '6': {
          feId: 'Link',
          change: true,
          translate: false,
          keyvalue: false,
          name: getDesc('Link'),
          value: getValue('Link'),
        },
        '7': {
          feId: 'Email',
          change: true,
          translate: false,
          keyvalue: false,
          name: getDesc('Email'),
          value: getValue('Email'),
        },
        '8': {
          feId: 'ProducedIn',
          change: true,
          translate: false,
          keyvalue: false,
          name: getDesc('ProducedIn'),
          value: getValue('ProducedIn'),
        },
      },
    },
    {
      id: 'Storage',
      open: false,
      show: true,
      attributes: {
        '1': {
          feId: 'WidgetHeadline_Storage',
          change: false,
          translate: true,
          keyvalue: false,
          name: getDesc('WidgetHeadline_Storage'),
          value: getValue('WidgetHeadline_Storage'),
        },
        '2': {
          feId: 'StorageFields',
          change: true,
          translate: true,
          keyvalue: true,
          name: getDesc('StorageFields'),
          value: getValue('StorageFields'),
        },
      },
    },
  ],
  style: {
    objects: {
      '1': {
        name: 'StyleProperty',
        value: [''],
      },
    },
  },
};

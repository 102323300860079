//
import { IWidget } from 'src/types/product';
import { FooterWidget } from './widgets/_footerWidget';
import { ProductHead } from './widgets/_productHead';
import { HoverButton } from './widgets/_hoverButton';
import { ProductInfo } from './widgets/_productInfo';
import { Certificates } from './widgets/_certificates';

// ----------------------------------------------------------------------

export const _widgets: IWidget[] = [
  HoverButton,
  ProductHead,
  ProductInfo,
  FooterWidget,
  Certificates,
];

import { ILangWidget } from 'src/types/product';

export const LangDocument: ILangWidget = [
  {
    feId: 'DocumentName',
    desc: [
      { lang: 'en', val: 'Document Name' },
      { lang: 'de', val: 'Dokumentenname' }, // Assuming 'Document Name' is translated to 'Dokumentenname'
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
];
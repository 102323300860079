import { ILangWidget } from 'src/types/product';

export const LangCertificates: ILangWidget = [
  {
    feId: 'WidgetHeadline_Certificates',
    desc: [
      { lang: 'en', val: 'Widget Headline' },
      { lang: 'de', val: 'Zertifikate' },
    ],
    value: [
      { lang: 'en', val: ['Certificates'] },
      { lang: 'de', val: ['Zertifikate'] },
    ],
  },
  {
    feId: 'ShowWidgetHeadline',
    desc: [
      { lang: 'en', val: 'Show Widget Headline' },
      { lang: 'de', val: 'Widget-Überschrift anzeigen' },
    ],
    value: [
      { lang: 'en', val: ['X'] },
      { lang: 'de', val: ['X'] },
    ],
  },
  {
    feId: 'CertificateName_Naturland',
    desc: [
      { lang: 'en', val: 'Certificate Name' },
      { lang: 'de', val: 'Zertifikatname' },
    ],
    value: [
      { lang: 'en', val: ['Naturland'] },
      { lang: 'de', val: ['Naturland'] },
    ],
  },
  {
    feId: 'CertificatePicture_Naturland',
    desc: [
      { lang: 'en', val: 'Certificate Picture' },
      { lang: 'de', val: 'Zertifikatbild' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'CertificatePictureURL_Naturland',
    desc: [
      { lang: 'en', val: 'Certificate Picture URL' },
      { lang: 'de', val: 'Zertifikatbild URL' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'CertificateNumber_Naturland',
    desc: [
      { lang: 'en', val: 'Certificate Number' },
      { lang: 'de', val: 'Zertifikatsnummer' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'Organization_Naturland',
    desc: [
      { lang: 'en', val: 'Organization' },
      { lang: 'de', val: 'Naturland -Verband für ökologischen Landbau e.V.' },
    ],
    value: [
      { lang: 'en', val: ['Naturland - Association for Organic Agriculture'] },
      { lang: 'de', val: ['Naturland -Verband für ökologischen Landbau e.V.'] },
    ],
  },
  {
    feId: 'Website_Naturland',
    desc: [
      { lang: 'en', val: 'Website' },
      { lang: 'de', val: 'Webseite' },
    ],
    value: [
      { lang: 'en', val: ['www.naturland.de'] },
      { lang: 'de', val: ['www.naturland.de'] },
    ],
  },
  {
    feId: 'CertificationYear_Naturland',
    desc: [
      { lang: 'en', val: 'Certification Year' },
      { lang: 'de', val: 'Zertifizierungsjahr' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'VerificationDocument_Naturland',
    desc: [
      { lang: 'en', val: 'Verification Document' },
      { lang: 'de', val: 'Verifizierungsdokument' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'CertificationCriteria_Naturland',
    desc: [
      { lang: 'en', val: 'Certification Criteria' },
      { lang: 'de', val: 'Zertifizierungskriterien' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'CertificationProcess_Naturland',
    desc: [
      { lang: 'en', val: 'Certification Process' },
      { lang: 'de', val: 'Zertifizierungsprozess' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
];
import { ILangWidget } from 'src/types/product';

export const LangActionButton: ILangWidget = [
  {
    feId: 'ButtonName',
    desc: [
      { lang: 'en', val: 'Name' },
      { lang: 'de', val: 'Aktion' }, // Assuming 'Action' is translated to 'Aktion'
    ],
    value: [
      { lang: 'en', val: ['Action'] },
      { lang: 'de', val: ['Aktion'] }, // Same translation for the value
    ],
  },
  {
    feId: 'ButtonUrl',
    desc: [
      { lang: 'en', val: 'Url' },
      { lang: 'de', val: 'Url' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
];
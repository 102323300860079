//
import { v4 as uuid } from 'uuid';
import { IWidget } from 'src/types/product';
import { getDesc, getValue } from '../_langWidgets';

// ----------------------------------------------------------------------

export const ShowcaseVideo: IWidget = {
  id: uuid(),
  name: 'ShowcaseVideo',
  order: 0,
  category: 'Showcase',
  open: false,
  label: {
    id: 'showcasevideo',
    name: 'Video',
    color: 'black',
  },
  childs: [
    {
      id: 'Head',
      open: true,
      show: true,
      attributes: {
        '1': {
          feId: 'YoutubeEmbedID',
          change: false,
          translate: false,
          keyvalue: false,
          name: getDesc('YoutubeEmbedID'),
          value: getValue('YoutubeEmbedID'),
        },
      },
    },
  ],
  style: {
    objects: {
      '1': {
        name: 'Orientation',
        value: ['center'],
      },
    },
  },
};

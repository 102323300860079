//
import { IWidget } from 'src/types/product';
import { Heading } from './components/_heading';
import { Subheading } from './components/_subheading';
import { Textblock } from './components/_textblock';
import { ActionButton } from './components/_actionButton';
import { ShowcaseCarousel } from './components/_showcaseCarousel';
import { ShowcaseImage } from './components/_showcaseImage';
import { ShowcaseVideo } from './components/_showcaseVideo';
import { Document } from './components/_document';
// ----------------------------------------------------------------------

export const _components: IWidget[] = [
  Heading,
  Subheading,
  Textblock,
  ActionButton,
  ShowcaseCarousel,
  ShowcaseImage,
  ShowcaseVideo,
  Document,
];

import { ILangWidget } from 'src/types/product';

export const LangShowcaseVideo: ILangWidget = [
  {
    feId: 'YoutubeEmbedID',
    desc: [
      { lang: 'en', val: 'Youtube Embed ID' },
      { lang: 'de', val: 'Youtube Embed ID' }, // Assuming no translation required for 'Youtube Embed ID'
    ],
    value: [
      { lang: 'en', val: [''] },
      { lang: 'de', val: [''] },
    ],
  },
];
import { ILangWidget } from 'src/types/product';

export const LangSubheading: ILangWidget = [
  {
    feId: 'SubheadingText',
    desc: [
      { lang: 'en', val: 'Subheading' },
      { lang: 'de', val: 'Untertitel' }, // Assuming no translation required for 'Youtube Embed ID'
    ],
    value: [
      { lang: 'en', val: ['Lorem Ipsum'] },
      { lang: 'de', val: ['Lorem Ipsum'] },
    ],
  },
 
];
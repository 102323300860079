import { ILangWidget } from 'src/types/product';

export const LangShowcaseImage: ILangWidget = [
  {
    feId: 'Ratio',
    desc: [
      { lang: 'en', val: 'Ratio' },
      { lang: 'de', val: 'Verhältnis' }, // Assuming 'Ratio' translates to 'Verhältnis' in German
    ],
    value: [
      { lang: 'en', val: ['1/1'] },
      { lang: 'de', val: ['1/1'] },
    ],
  },
  {
    feId: 'Image',
    desc: [
      { lang: 'en', val: 'Image' },
      { lang: 'de', val: 'Bild' }, // Assuming 'Image' translates to 'Bild'
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'ImgFile',
    desc: [
      { lang: 'en', val: 'Image File' },
      { lang: 'de', val: 'Bilddatei' }, // Assuming 'Image File' translates to 'Bilddatei'
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
];
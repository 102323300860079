import * as Yup from 'yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
// hooks
import { firestore } from 'src/firebase/firebaseInit';
import { useAuthContext } from 'src/auth/hooks';
import { useGetCompanyId } from 'src/api/company';
import { useLocales } from 'src/locales';
// components
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFTextField, RHFAutocomplete } from 'src/components/hook-form';
import Iconify from 'src/components/iconify';
import { countries } from 'src/assets/data';
import { CardHeader, Divider } from '@mui/material';
import { doc, setDoc } from 'firebase/firestore';

// ----------------------------------------------------------------------

export default function AccountLegal() {
  const { t } = useLocales();
  const { enqueueSnackbar } = useSnackbar();

  const { user } = useAuthContext();

  const { company, companyLoading } = useGetCompanyId(user?.company);

  const UpdateCompanySchema = Yup.object().shape({
    // Validierung der legal-Informationen
    legal: Yup.object().shape({
      phoneC: Yup.string().required('Phone is required'),
      emailC: Yup.string().required('Address is required'),
      nameAR: Yup.string().required('Authorized Representative Name is required'),
      titleAR: Yup.string().required('Title is required'),
      numberCRI: Yup.string().required('Commercial Register Number is required'),
      courtCRI: Yup.string().required('Competent Court is required'),
      infoVAT: Yup.string().required('VAT Information is required'),
    }),
  });

  const methods = useForm({
    resolver: yupResolver(UpdateCompanySchema),
    defaultValues: {
      legal: {
        phoneC: '',
        emailC: '',
        nameAR: '',
        titleAR: '',
        numberCRI: '',
        courtCRI: '',
        infoVAT: '',
      },
    },
  });

  const {
    setValue,
    handleSubmit,
    formState: { isSubmitting, isDirty },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      console.log(data);
      await new Promise((resolve) => setTimeout(resolve, 500));
      const companyItem = company;

      // legal-Daten aktualisieren
      companyItem.legal = {
        phoneC: data.legal.phoneC,
        emailC: data.legal.emailC,
        nameAR: data.legal.nameAR,
        titleAR: data.legal.titleAR,
        numberCRI: data.legal.numberCRI,
        courtCRI: data.legal.courtCRI,
        infoVAT: data.legal.infoVAT,
      };

      const docu = doc(firestore, 'company', company.id);
      setDoc(docu, companyItem).then(() => {
        enqueueSnackbar('Update success!');
      });
    } catch (error) {
      console.error(error);
    }
  });

  useEffect(() => {
    // Check if company data is available and not loading
    if (!companyLoading && company) {
      // Set the form values with the company data
      setValue('legal.phoneC', company.legal?.phoneC || '');
      setValue('legal.emailC', company.legal?.emailC || '');
      setValue('legal.nameAR', company.legal?.nameAR || '');
      setValue('legal.titleAR', company.legal?.titleAR || '');
      setValue('legal.numberCRI', company.legal?.numberCRI || '');
      setValue('legal.courtCRI', company.legal?.courtCRI || '');
      setValue('legal.infoVAT', company.legal?.infoVAT || '');
    }
  }, [company, companyLoading, setValue]);

  return (
    <div>
      {!companyLoading && (
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <Card sx={{ pb: 3 }}>
            <Box sx={{ px: 3 }}>
              <CardHeader
                title={t('mngmt.companyaccount.legal.contactInformation')}
                sx={{ textAlign: 'left', px: 0, pb: 3 }}
              />
              <Box
                rowGap={3}
                columnGap={2}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                }}
              >
                <RHFTextField name="legal.phoneC" label={t('mngmt.companyaccount.legal.phone')} />

                <RHFTextField name="legal.emailC" label={t('mngmt.companyaccount.legal.email')} />
              </Box>
            </Box>

            <Divider sx={{ borderStyle: 'dashed', py: 1 }} />
            <Box sx={{ px: 3 }}>
              <CardHeader
                title={t('mngmt.companyaccount.legal.authorizedRepresentative')}
                sx={{ textAlign: 'left', px: 0, pb: 3 }}
              />
              <Box
                rowGap={3}
                columnGap={2}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                }}
              >
                <RHFTextField
                  name="legal.nameAR"
                  label={t('mngmt.companyaccount.legal.nameRepresentative')}
                />

                <RHFTextField
                  name="legal.titleAR"
                  label={t('mngmt.companyaccount.legal.titleRepresentative')}
                />
              </Box>
            </Box>

            <Divider sx={{ borderStyle: 'dashed', py: 1 }} />
            <Box sx={{ px: 3 }}>
              <CardHeader
                title={t('mngmt.companyaccount.legal.commercialRegisterInformation')}
                sx={{ textAlign: 'left', px: 0, pb: 3 }}
              />
              <Box
                rowGap={3}
                columnGap={2}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                }}
              >
                <RHFTextField
                  name="legal.numberCRI"
                  label={t('mngmt.companyaccount.legal.commercialRegisterNumber')}
                />

                <RHFTextField
                  name="legal.courtCRI"
                  label={t('mngmt.companyaccount.legal.competentRegistryCourt')}
                />
              </Box>
            </Box>

            <Divider sx={{ borderStyle: 'dashed', py: 1 }} />
            <Box sx={{ px: 3 }}>
              <CardHeader
                title={t('mngmt.companyaccount.legal.vatInformation')}
                sx={{ textAlign: 'left', px: 0, pb: 3 }}
              />
              <Box
                rowGap={3}
                columnGap={2}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                }}
              >
                <RHFTextField name="legal.infoVAT" label={t('mngmt.companyaccount.legal.vatId')} />
              </Box>
            </Box>

            <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3, pr: 3 }}>
              <LoadingButton
                type="submit"
                color="primary"
                variant="contained"
                loading={isSubmitting}
                disabled={!isDirty}
              >
                {t('general.saveButton')}
              </LoadingButton>
            </Stack>
          </Card>
        </FormProvider>
      )}
    </div>
  );
}

//
import { v4 as uuid } from 'uuid';
import { IWidget } from 'src/types/product';
import { getDesc, getValue } from '../_langWidgets';

// ----------------------------------------------------------------------

export const ActionButton: IWidget = {
  id: uuid(),
  name: 'ActionButton',
  order: 0,
  category: 'Action',
  open: false,
  label: {
    id: 'actionbutton',
    name: 'Button',
    color: 'black',
  },
  childs: [
    {
      id: 'Head',
      open: true,
      show: true,
      attributes: {
        '1': {
          feId: 'ButtonName',
          change: true,
          translate: true,
          keyvalue: false,
          name: getDesc('ButtonName'),
          value: getValue('ButtonName'),
        },
        '2': {
          feId: 'ButtonUrl',
          change: false,
          translate: false,
          keyvalue: false,
          name: getDesc('ButtonUrl'),
          value: getValue('ButtonUrl'),
        },
      },
    },
  ],
  style: {
    objects: {
      '1': {
        name: 'Orientation',
        value: ['center'],
      },
    },
  },
};

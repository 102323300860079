//
import { v4 as uuid } from 'uuid';
import { IWidget } from 'src/types/product';
import { getDesc, getValue } from '../_langWidgets';

// ----------------------------------------------------------------------

export const Document: IWidget = {
  id: uuid(),
  name: 'Document',
  order: 0,
  category: 'Data',
  open: false,
  label: {
    id: 'document',
    name: 'Document',
    color: 'black',
  },
  childs: [
    {
      id: 'Head',
      open: true,
      show: true,
      attributes: {
        '1': {
          feId: 'DocumentName',
          change: true,
          translate: true,
          keyvalue: false,
          name: getDesc('DocumentName'),
          value: getValue('DocumentName'),
        },
      },
    },
  ],
  style: {
    objects: {
      '1': {
        name: '',
        value: [''],
      },
    },
  },
};

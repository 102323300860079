import axios from 'axios';
import { BACKEND_PW, BACKEND_USER, CIRCLIFY_BACKEND } from '../config-global';

export async function getKeycloak(): Promise<string> {
    const user = BACKEND_USER;
    const pw = BACKEND_PW;
  
    const body = {
      username: user,
      password: pw,
    };
    const url: string = `${CIRCLIFY_BACKEND}/auth`;
    let key: string = '';
    try {
      const response = await axios.post(url, body);
      if (response.status === 200) {
        key = response.data.access_token;
      }
    } catch (error) {
      console.log(error);
    }
    return key;
  }
//
import { v4 as uuid } from 'uuid';
import { IWidget } from 'src/types/product';
import { getDesc, getValue } from '../_langWidgets';

// ----------------------------------------------------------------------

export const ShowcaseCarousel: IWidget = {
  id: uuid(),
  name: 'ShowcaseCarousel',
  order: 0,
  category: 'Showcase',
  open: false,
  label: {
    id: 'showcasecarousel',
    name: 'Carousel',
    color: 'black',
  },
  childs: [
    {
      id: 'Head',
      open: true,
      show: true,
      attributes: {
        
        '1': {
          feId: 'Image1',
          change: false,
          translate: false,
          keyvalue: false,
          name: getDesc('Image1'),
          value: getValue('Image1'),
        },
        '2': {
          feId: 'Img1File',
          change: false,
          translate: false,
          keyvalue: false,
          name: getDesc('Img1File'),
          value: getValue('Img1File'),
        },
        '3': {
          feId: 'Image2',
          change: false,
          translate: false,
          keyvalue: false,
          name: getDesc('Image2'),
          value: getValue('Image2'),
        },
        '4': {
          feId: 'Img2File',
          change: false,
          translate: false,
          keyvalue: false,
          name: getDesc('Img2File'),
          value: getValue('Img2File'),
        },
        '5': {
          feId: 'Image3',
          change: false,
          translate: false,
          keyvalue: false,
          name: getDesc('Image3'),
          value: getValue('Image3'),
        },
        '6': {
          feId: 'Img3File',
          change: false,
          translate: false,
          keyvalue: false,
          name: getDesc('Img3File'),
          value: getValue('Img3File'),
        },
        '7': {
          feId: 'Image4',
          change: false,
          translate: false,
          keyvalue: false,
          name: getDesc('Image4'),
          value: getValue('Image4'),
        },
        '8': {
          feId: 'Img4File',
          change: false,
          translate: false,
          keyvalue: false,
          name: getDesc('Img4File'),
          value: getValue('Img4File'),
        },
      },
    },
  ],
  style: {
    objects: {
      '1': {
        name: 'Ratio',
        value: ['1/1'],
      },
    },
  },
};

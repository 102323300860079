import { useState, useCallback } from 'react';
// @mui
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Container from '@mui/material/Container';
// hooks
import { useLocales } from 'src/locales';
// routes
import { paths } from 'src/routes/paths';
// components
import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
//
import { useTheme } from '@mui/material';
import { useGetCompanyId } from 'src/api/company';
import { useAuthContext } from 'src/auth/hooks';
import AccountGeneral from '../account-general';
import AccountBilling from '../billing/account-billing';
import AccountLegal from '../account-legal';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AccountView() {
  const theme = useTheme();
  const { user } = useAuthContext();
  const { t } = useLocales();
  const settings = useSettingsContext();

  const { company } = useGetCompanyId(user?.company);

  const TABS = [
    {
      value: 'general',
      label: t('mngmt.companyaccount.general'),
      icon: <Iconify icon="solar:user-id-bold" width={24} />,
    },
    {
      value: 'legal',
      label: t('mngmt.companyaccount.legal.legal'),
      icon: <Iconify icon="solar:card-2-bold" width={24} />,
    },
    {
      value: 'billing',
      label: t('mngmt.companyaccount.billing'),
      icon: <Iconify icon="solar:bill-list-bold" width={24} />,
    },
  ];

  const [currentTab, setCurrentTab] = useState('general');

  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  }, []);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={t('mngmt.companyaccount.title')}
        links={[{ name: company?.name || '' }, { name: t('mngmt.companyaccount.title') }]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <div>
        <Tabs
          value={currentTab}
          onChange={handleChangeTab}
          sx={{
            mb: { xs: 3, md: 5 },
            '& .MuiTabs-indicator': {
              backgroundColor: theme.palette.primary.main,
            },
            '& .Mui-selected': {
              color: theme.palette.primary.main,
            },
          }}
        >
          {TABS.map((tab) => (
            <Tab
              key={tab.value}
              label={tab.label}
              icon={tab.icon}
              value={tab.value}
              disabled={tab.value === 'billing'}
            />
          ))}
        </Tabs>
        {currentTab === 'general' && <AccountGeneral />}

        {currentTab === 'legal' && <AccountLegal />}

        {currentTab === 'billing' && <AccountBilling />}
      </div>
    </Container>
  );
}
